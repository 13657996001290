<template>
  <div class="live-wrapper">
    <Slide />
    <div class="live-conetnt">
      <Screen :isShow="true" :jumpObject="jumpObject" :placeholder="'搜索视频名称'" @getList="getList" />
      <div class="list-player">
        <watch-item
          v-if="Boolean(list.length) && getFnCodeList && getFnCodeList.includes('VIDEO_LOOK')"
          :list="list"
          :isShow="false"
        />
        <a-empty v-else>
          <template #description>
            <p>暂无视频</p>
          </template>
        </a-empty>
      </div>
      <Pagination
        v-if="getFnCodeList && getFnCodeList.includes('VIDEO_LOOK')"
        :params="params"
        :total="total"
        @getList="getList"
      />
    </div>
  </div>
</template>

<script>
import api from '@/services';
import WatchItem from '@/components/WatchItem.vue';
import Pagination from '@/components/Pagination.vue';
import Screen from '@/components/Screen.vue';
import { ref, provide, onMounted, computed } from 'vue';
import Slide from '@/components/Slide.vue';
import { getLabelList } from '@/store/publicFunction.js';
import { setIsType } from '@/store/sharedData.js';
import { fieldName, video_attribute, typeText } from '@/store/fieldConfiguration';
import { FormatTime } from '@/utils/tool';
export default {
  components: {
    WatchItem,
    Pagination,
    Screen,
    Slide
  },
  setup() {
    setIsType(2);

    // 获取标签筛选列表
    const labelList = ref(null);
    provide('labelList', labelList);
    const setLabelList = async () => {
      labelList.value = await getLabelList('getVideoLabelList');
    };
    setLabelList();

    const params = ref({
      startOnlineTime: null,
      endOnlineTime: null,
      labelList: null,
      userIdList: [],
      title: '',
      type: null,
      curPage: 1,
      // userId: 0,
      pageSize: 10,
      sort: ''
    });
    const total = ref(0);
    const list = ref([]);
    provide('params', params);
    const getList = async () => {
      try {
        if (params.value.startOnlineTime) {
          params.value.startOnlineTime = `${FormatTime(params.value.startOnlineTime)} 00:00:00`;
        }
        if (params.value.endOnlineTime) {
          params.value.endOnlineTime = `${FormatTime(params.value.endOnlineTime)} 23:59:59`;
        }
        const { data } = await api.getVideoList(params.value);
        console.log(data)
        total.value = data?.total || 0;
        if (!data.total) {
          list.value = [];
          return false;
        }
        if(data?.records?.length > 0) {
            list.value = data?.records.map(item => {
            item.type = typeText[item.type];
            let paramsJson = JSON.stringify(item);
            video_attribute.forEach((oldKey, index) => {
              const reg = oldKey;
              paramsJson = paramsJson.replace(reg, fieldName[index]);
            });
            return JSON.parse(paramsJson);
          });
        } else {
          list.value = [];
        }
      } catch (error) {
        console.error(error);
      }
    };
    onMounted(() => {
      getList();
    });
    const getFnCodeList = computed(() => {
      return JSON.parse(localStorage.getItem('fnCodeList'));
    });
    return {
      getFnCodeList,
      getList,
      params,
      list,
      total,
      jumpObject: {
        name: '上传视频',
        pathName: 'configuration'
      }
    };
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.live-wrapper {
  .live-video();
}
</style>
