<template>
  <a-carousel autoplay>
    <div class="lg" v-for="item in imgList">
      <img @click="goLink(item)" :src="item.pic" alt="" />
    </div>
  </a-carousel>
</template>
<script>
import { onMounted, ref } from 'vue';
import api from '@/services';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const imgList = ref([])
    const router = useRouter();
    const getSlide = async () => {
      const { data } = await api.getSlide();
      imgList.value = data
    };
    const goLink = (item) => {
      if(item.link) {
        window.open(item.link)
      }
    }
    onMounted(() => {
      getSlide();
    });
    return {
      imgList,
      goLink
    }
  }
};
</script>
<style lang="less" scoped>
.ant-carousel :deep(.slick-slide) {
  .lg {
    width: 1440px;
    height: 384px;
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
</style>
